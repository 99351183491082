<template>
	<div class="goods-detail">
		<div class="preview-wrap">
			<!-- 自定义标签 -->
			<div class="goods-diy diy-discount" v-if="goodsSkuDetail.price_label">
				<span>{{goodsSkuDetail.price_label}}</span>
			</div>
			<div class="goods-diy diy-stock" :style="{background:goodsSkuDetail.label_color}" v-if="goodsSkuDetail.stock_label">
				<span>{{goodsSkuDetail.stock_label}}</span>
			</div>
			<div class="goods-left-diy diy-own" v-if="goodsSkuDetail.own_label">
				<span><i class="iconfont icon-sandian"></i>{{goodsSkuDetail.own_label}}</span>
			</div>
			<div class="video-player-wrap" :class="{ show: switchMedia == 'video' }" v-if="goodsSkuDetail.video_url != ''">
				<video-player v-if="goodsSkuDetail.video_url != ''" ref="videoPlayer" :playsinline="true" :options="playerOptions"
				 @play="onPlayerPlay($event)" @pause="onPlayerPause($event)" @ended="onPlayerEnded($event)" @waiting="onPlayerWaiting($event)"
				 @playing="onPlayerPlaying($event)" @loadeddata="onPlayerLoadeddata($event)" @timeupdate="onPlayerTimeupdate($event)"
				 @canplay="onPlayerCanplay($event)" @canplaythrough="onPlayerCanplaythrough($event)" @statechanged="playerStateChanged($event)"
				 @ready="playerReadied"></video-player>
				<div class="media-mode" v-if="goodsSkuDetail.video_url != ''">
					<span :class="{ 'ns-bg-color': switchMedia == 'video' }" @click="switchMedia = 'video'">{{$lang('video',$route)}}</span>
					<span :class="{ 'ns-bg-color': switchMedia == 'img' }" @click="switchMedia = 'img'">{{$lang('picture',$route)}}</span>
				</div>
			</div>
			<div class="magnifier-wrap">
				<pic-zoom ref="PicZoom" :url="$img(picZoomUrl)" :scale="2"></pic-zoom>
			</div>

			<div class="spec-items">
				<span class="left-btn iconfont icon-arrow-left-copy" :class="{ move: moveThumbLeft }" @click="changeThumbImg('prev')"></span>
				<span class="right-btn iconfont icon-arrow-right" :class="{ move: moveThumbRight }" @click="changeThumbImg('next')"></span>
				<ul :style="{ left: 30 + thumbPosition + 'px' }">
					<!-- 商品缩率图 -->
					<li v-for="(item, index) in goodsSkuDetail.sku_images" :key="index" @click="switchMedia = 'img'" @mousemove="picZoomUrl = item" :class="{ selected: picZoomUrl == item }">
						<img :src="$img(item, { size: 'small' })" @error="imageErrorSpec(index)" />
					</li>
				</ul>
			</div>
		</div>

		<!-- 商品信息 -->
		<div class="basic-info-wrap" v-loading="loading">
			<h1>{{ goodsSkuDetail.sku_name }}</h1>
			<p class="desc ns-text-color" v-if="goodsSkuDetail.introduction">{{ goodsSkuDetail.introduction }}</p>

			<div class="discount-banner ns-bg-color" v-if="goodsSkuDetail.promotion_type == 1 && discountTimeMachine.currentTime && addonIsExit.discount">
				<div class="activity-name">
					<i class="discount-icon iconfont iconicon_naozhong"></i>
					<span>{{$lang('discount',$route)}}</span>
				</div>
				<div class="surplus-time">
					<span>{{ discountText }}</span>
					<count-down class="count-down" v-on:start_callback="countDownS_cb()" v-on:end_callback="countDownE_cb()"
					 :currentTime="discountTimeMachine.currentTime" :startTime="discountTimeMachine.startTime" :endTime="discountTimeMachine.endTime"
					 :dayTxt="$lang('day',$route)" :hourTxt="$lang('hour',$route)" :minutesTxt="$lang('minute',$route)" 
					 :secondsTxt="$lang('seconds',$route)"></count-down>
				</div>
			</div>

			<div class="item-block">
				<div class="promotion-price">
					<dl class="item-line">
						<dt class="ns-text-color-gray">{{$lang('s_price',$route)}}</dt>
						<dd>
							<em class="yuan ns-text-color">{{ siteInfo.price_unit }}</em>
							<span class="price ns-text-color">{{ goodsSkuDetail.discount_price }}</span>
						</dd>
					</dl>
					<dl class="item-line" v-if="goodsSkuDetail.promotion_type == 1 && discountTimeMachine.currentTime">
						<dt class="ns-text-color-gray">{{$lang('o_price',$route)}}</dt>
						<dd>
							<em class="market-yuan">{{ siteInfo.price_unit }}</em>
							<span class="market-price">{{ goodsSkuDetail.price }}</span>
						</dd>
					</dl>
					<dl class="item-line" v-if="goodsSkuDetail.member_price > 0">
						<dt class="ns-text-color-gray">{{$lang('m_price',$route)}}</dt>
						<dd>
							<em class="market-yuan">{{ siteInfo.price_unit }}</em>
							<span class="member-price">{{ goodsSkuDetail.member_price }}</span>
						</dd>
					</dl>
					<dl class="item-line" v-if="goodsSkuDetail.market_price > 0">
						<dt class="ns-text-color-gray">{{$lang('mk_price',$route)}}</dt>
						<dd>
							<em class="market-yuan">{{ siteInfo.price_unit }}</em>
							<span class="market-price">{{ goodsSkuDetail.market_price }}</span>
						</dd>
					</dl>

					<div class="statistical">
						<ul>
							<li>
								<p>{{$lang('evaluate',$route)}}</p>
								<span>{{ goodsSkuDetail.evaluate }}</span>
							</li>
							<li>
								<p>{{$lang('sales',$route)}}</p>
								<span>{{ goodsSkuDetail.sale_num }}{{ goodsSkuDetail.unit }}</span>
							</li>
						</ul>
					</div>
					<dl class="item-line" v-if="goodsSkuDetail.brand_id > 0">
						<dt>{{$lang('brand',$route)}}</dt>
						<dd>
							<router-link :to="{ path: '/list.html', query: { brand_id: goodsSkuDetail.brand_id } }" target="_blank" :title="goodsSkuDetail.brand_name">
								<i class="ns-text-color">{{ goodsSkuDetail.brand_name }}</i>
							</router-link>
						</dd>
					</dl>
					<dl class="item-line coupon-list" v-if="addonIsExit.coupon && couponList.length">
						<dt class="ns-text-color-gray">{{$lang('coupon',$route)}}</dt>
						<div>
							<dd>
								<p v-for="(item, index) in couponList" :key="index" class="ns-text-color" @click="receiveCoupon(item.coupon_type_id)">
									<span class="ns-border-color" v-if="item.type == 'discount'">{{ item.discount }}折</span>
									<span class="ns-border-color" v-if="item.type == 'reward'">{{ siteInfo.price_unit }}{{ item.money }}</span>
									<label v-if="item.type == 'discount'">{{ item.discount }}折</label>
									<label v-if="item.type == 'reward'">{{ siteInfo.price_unit }}{{ item.money }}</label>
								</p>
							</dd>
						</div>
					</dl>
					<dl class="item-line manjian" v-if="manjian.manjian" style="align-items: top;">
						<dt>满减</dt>
						<dd>
							<i class="i-activity-flag ns-text-color ns-border-color">{{ manjian.manjian_name }}</i>
							<span>{{ manjian.manjian }}</span>
						</dd>
					</dl>
					<dl class="item-line manjian" v-if="manjian.mansong != undefined">
						<dt>满送</dt>
						<dd style="display: flex;align-items: top;">
							<i class="i-activity-flag ns-text-color ns-border-color" style="height: 14px;line-height: 14px;margin-top: 5px;">{{ manjian.manjian_name }}</i>
							<div>
								{{ manjian.mansong }}
							</div>
						</dd>
					</dl>
				</div>
			</div>
			<dl class="item-line service">
				<dt>{{$lang('service',$route)}}</dt>
				<div class="service-box">
					<p><span class="iconfont icon-peisong"></span><span style="font-weight: 600;">{{$lang("common.delivery_title")}}</span>{{ goodsSkuDetail.shipping_label }}</p>
					<p><span class="iconfont icon-yunfei"></span><span style="font-weight: 600;">{{$lang("common.postage_title")}}</span>{{$lang("common.postage")}}</p>
					<p><span class="iconfont icon-baozhang"></span><span style="font-weight: 600;">{{$lang("common.guarantee_title")}}</span>{{$lang("common.guarantee")}}</p>
					<p><span class="iconfont icon-baojia"></span><span style="font-weight: 600;">{{$lang("common.price_guarantee_title")}}</span>{{$lang("common.price_guarantee")}}</p>
				</div>
				
			</dl>
			<p class="zhifu">
				{{$lang("common.payment_title")}}
				<span class="iconfont icon-xinyongka"></span>{{$lang("common.credit_card")}}
				<span class="iconfont icon-weixinzhifu"></span>{{$lang("common.we_pay")}}
				<span class="iconfont icon-zhifubao"></span>{{$lang("common.alipay")}}
			</p>
			<hr class="divider" />
			<div class="sku-list" v-if="goodsSkuDetail.goods_spec_format">
				<dl class="item-line" v-for="(item, index) in goodsSkuDetail.goods_spec_format" :key="index">
					<dt>{{ item.spec_name }}</dt>
					<dd>
						<ul>
							<li v-for="(item_value, index_value) in item.value" :key="index_value">
								<div :class="{
										'selected ns-border-color': selectSkuId == item_value.sku_id,
										disabled: item_value['disabled'] || (!item_value['selected'] && specDisabled)
									}"
								 @click="changeSpec(item_value.sku_id, item_value.spec_id)">
									<img v-if="item_value.image" :src="$img(item_value.image, { size: 'small' })" />
									<span>{{ item_value.spec_value_name }}</span>
									<i class="iconfont iconduigou1 ns-text-color"></i>
								</div>
							</li>
						</ul>
					</dd>
				</dl>
			</div>

			<div class="buy-number">
				<dl class="item-line">
					<dt>{{$lang('quantity',$route)}}</dt>
					<dd>
						<div class="num-wrap">
							<el-input v-model="number" placeholder="0" @input="keyInput()"></el-input>
							<div class="operation">
								<span class="increase el-icon-caret-top" @click="changeNum('+')"></span>
								<span class="decrease el-icon-caret-bottom" @click="changeNum('-')"></span>
							</div>
						</div>
						<span class="unit">{{ goodsSkuDetail.unit }}</span>
						<!-- 限购 -->
						<em v-if="goodsSkuDetail.is_limit==1" class="restrictions">
							<span v-if="goodsSkuDetail.limit_type==1">(限购{{goodsSkuDetail.max_buy}}件)</span> 
							<span v-else>(每人限购{{goodsSkuDetail.max_buy}}件)</span> 
						</em>
					</dd>
				</dl>
			</div>

			<dl class="item-line buy-btn">
				<dt></dt>
				<dd v-if="goodsSkuDetail.goods_state == 1">
					<template v-if="goodsSkuDetail.stock == 0 && goodsSkuDetail.stock_delay == 0">
						<el-button type="info" plain disabled>{{$lang('out_stock',$route)}}</el-button>
					</template>
					
					<template v-else-if="goodsSkuDetail.max_buy != 0 && goodsSkuDetail.purchased_num >= goodsSkuDetail.max_buy">
						<el-button type="info" plain disabled>已达最大限购数量</el-button>
					</template>

					<template v-else>
						<el-button type="primary" plain @click="buyNow">{{$lang('buynow',$route)}}</el-button>
						<el-button type="primary" icon="el-icon-shopping-cart-2" v-if="goodsSkuDetail.is_virtual == 0" @click="joinCart">{{$lang('joincart',$route)}}</el-button>
					</template>
					
					<div href="javascript:;" class="go-phone">
						<img src="@/assets/images/goods/qrcode.png" />
						<div class="qrcode-wrap"><img :src="qrcode" alt="二维码图片" /></div>
					</div>
				</dd>

				<dd v-else>
					<template>
						<el-button type="info" plain disabled>该商品已下架</el-button>
					</template>
					<div href="javascript:;" class="go-phone">
						<img src="@/assets/images/goods/qrcode.png" />
						<div class="qrcode-wrap"><img :src="qrcode" alt="二维码图片" /></div>
					</div>
				</dd>
			</dl>

			<dl class="item-line merchant-service" v-show="service_list.length">
				<dt>商品服务</dt>
				<div>
					<dd v-for="(item, index) in service_list" :key="index">
						<i class="el-icon-success"></i>
						<span class="ns-text-color-gray" :title="item.service_name">{{item.service_name}}</span>
					</dd>
				</div>
			</dl>
		</div>

		<!-- 组合套餐 -->
		<el-tabs class="bundling-wrap" v-model="tabBundling" @tab-click="bundlingChange" v-if="addonIsExit.bundling && bundling.length && bundling[0].bl_name">
			<el-tab-pane :label="item.bl_name" :name="'bundling_' + item.bl_id" v-for="(item, index) in bundling" :key="index">
				<div class="master">
					<div class="sku-img"><img :src="$img(goodsSkuDetail.sku_image, { size: 'mid' })" /></div>
					<div class="sku-name">{{ goodsSkuDetail.sku_name }}</div>
					<div class="sku-price ns-text-color">￥{{ goodsSkuDetail.price }}</div>
					<i class="el-icon-plus"></i>
				</div>
				<div class="operation">
					<div class="price-wrap">
						<span>组合套餐价</span>
						<strong class="bl-price ns-text-color">￥{{ item.bl_price }}</strong>
					</div>
					<el-button type="primary" size="medium" @click="$router.pushToTab('promotion/combo-' + item.bl_id)">立即购买</el-button>
					<i class="equal">=</i>
				</div>

				<div class="suits">
					<ul>
						<li v-for="(goods, goods_index) in item.bundling_goods" :key="goods_index" @click="$router.pushToTab({ path: '/product-' + goods.goods_id + '.html' })">
							<div class="sku-img"><img :src="$img(goods.sku_image, { size: 'mid' })" /></div>
							<div class="sku-name">{{ goods.sku_name }}</div>
							<div class="sku-price ns-text-color">A${{ goods.price }}</div>
						</li>
					</ul>
				</div>
			</el-tab-pane>
		</el-tabs>

		<div class="detail-wrap">
			<div class="goods-recommended">
				<goods-recommend route="goodsdetail" :sku-id="skuId" />
			</div>

			<el-tabs class="goods-tab" v-model="tabName" type="card" @tab-click="tabChange">
				<el-tab-pane :label="$lang('details',$route)" name="detail">
					<div class="goods_content" v-html="goodsSkuDetail.goods_content"></div>
				</el-tab-pane>
				<el-tab-pane :label="$lang('attribute',$route)" name="attr">
					<ul class="attr-list">
						<template v-if="goodsSkuDetail.goods_attr_format && goodsSkuDetail.goods_attr_format.length > 0">
							<li v-for="(item, index) in goodsSkuDetail.goods_attr_format" :key="index">{{ item.attr_name }}：{{ item.attr_value_name }}</li>
						</template>
					</ul>
				</el-tab-pane>
				<el-tab-pane v-if="evaluate_show" :label="evaluteCount.total ? $lang('reviews',$route) + '(' + evaluteCount.total + ')' : $lang('reviews',$route)" name="evaluate" class="evaluate">
					<template v-if="evaluteCount.total">
						<nav>
							<li :class="evaluaType == 0 ? 'selected' : ''" @click="evaluationType(0)">{{$lang('all_review')}}({{evaluteCount.total}})</li>
							<li :class="evaluaType == 1 ? 'selected' : ''" @click="evaluationType(1)">好评({{evaluteCount.haoping}})</li>
							<li :class="evaluaType == 2 ? 'selected' : ''" @click="evaluationType(2)">中评({{evaluteCount.zhongping}})</li>
							<li :class="evaluaType == 3 ? 'selected' : ''" @click="evaluationType(3)">差评({{evaluteCount.chaping}})</li>
						</nav>
						<ul class="list">
							<li v-for="(item, index) in goodsEvaluateList" :key="index">
								<div class="member-info">
									<img :src="$img(item.member_headimg)" @error="imageErrorEvaluate(index)" class="avatar" />
									<span>{{ item.member_name }}</span>
								</div>
								<div class="info-wrap">
									<el-rate v-model="item.star" disabled></el-rate>
									<p class="content">{{ item.content }}</p>
									<div class="img-list" v-if="item.images">
										<el-image v-for="(img, img_index) in item.images" :key="img_index" :src="$img(img)" :preview-src-list="item.imagesFormat"></el-image>
									</div>
									<div class="sku-info">
										<span>{{ item.sku_name }}</span>
										<span class="create-time"></span>
									</div>
									<div class="evaluation-reply" v-if="item.explain_first != ''">店家回复：{{ item.explain_first }}</div>
									<template v-if="item.again_is_audit == 1 ">
										<div class="review-evaluation">
											<span>追加评价</span>
											<span class="review-time">{{ $util.timeStampTurnTime(item.again_time) }}</span>
										</div>
										<p class="content">{{ item.again_content }}</p>
										<div class="img-list">
											<el-image v-for="(again_img, again_index) in item.again_images" :key="again_index" :src="$img(again_img)"
											 :preview-src-list="item.againImagesFormat"></el-image>
										</div>
										<div class="evaluation-reply" v-if="item.again_explain != ''">店家回复：{{ item.again_explain }}</div>
									</template>
								</div>
							</li>
						</ul>
						<div class="pager">
							<el-pagination background :pager-count="5" :total="total" :prev-text="$lang('previous_page')" :next-text="$lang('next_page')" :current-page.sync="currentPage"
							 :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
							 hide-on-single-page></el-pagination>
						</div>
					</template>
					<div class="empty" v-else>{{$lang('no_reviews')}}</div>
				</el-tab-pane>
				<template v-if="service">
					<el-tab-pane  v-if="service_is_display.is_display == 1" :label="service.title"  name="after_sale" class="after-sale"><div v-html="service.content"></div></el-tab-pane>
				</template>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	import PicZoom from 'vue-piczoom';
	import detail from './detail';
	import GoodsRecommend from '@/components/GoodsRecommend';
	export default {
		name: 'detail',
		components: {
			PicZoom,
			GoodsRecommend
		},
		mixins: [detail]
	};
</script>
<style lang="scss">
	@import './detail.scss';
</style>
